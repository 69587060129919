import React from 'react'
import { Link } from 'gatsby'
import {
  Flex,
  Heading,
  Text,
  BackgroundImage,
  Image,
  List,
  ListItem,
  HorizontalRule,
  Spacer,
  Container,
} from 'components'
import img from 'data/img/precision_trucks.jpg'
import Img from 'gatsby-image'

///TODO:autoslide pipe pictures under
//TODO:create a container which is just a cenetered Flex
//contact_sales
//puddling issues, water seeping into the foundation of your house? wehandle all these issues and more, give us a call for more information
const AboutPageTemplate = ({ paragraphs, image }) => (
  <>
    <Flex bg="brand.primary" p={16} hAlignContent="center" col color="white">
      <HorizontalRule />
      <Heading.h1>Who Are We?</Heading.h1>
      {/* <Heading.h3>What can we do to help?</Heading.h3> */}
    </Flex>
    <Flex wrap grow>
      <Flex col p={16} width={['100%', '50%']}>
        {paragraphs.map(p => (
          <Text p={16}>{p}</Text>
        ))}
        <Spacer />

        <Container my={32} bg="offWhite">
          <Heading.h3 color="brand.primary">
            Servicing the fine communities of:
          </Heading.h3>
          <List columns={2}>
            <ListItem>Alpharetta</ListItem>
            <ListItem>Dunwoody</ListItem>
            <ListItem>Johns Creek</ListItem>
            <ListItem>Cumming</ListItem>
            <ListItem>Kennesaw</ListItem>
            <ListItem>Atlanta</ListItem>
            <ListItem>Milton</ListItem>
            <ListItem>Roswell</ListItem>
            <ListItem>Acworth</ListItem>
            <ListItem>Canton</ListItem>
            <ListItem>Ball Ground</ListItem>
            <ListItem>Sandy Springs</ListItem>
            <ListItem>Smyrna</ListItem>
            <ListItem>Marietta</ListItem>
          </List>
        </Container>
        <Flex
          bg="brand.secondary"
          p={16}
          hAlignContent="center"
          vAlignContent="center"
          as={Link}
        >
          <Text
            color="white"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="lg"
          >
            Our Services
          </Text>
        </Flex>
      </Flex>

      <BackgroundImage
        fluid={image.fluid}
        display="flex"
        flexDirection="column"
        color="white"
        width={['100%', '50%']}
        minHeight={400}
      >
        <Spacer />
        <Flex
          bg="brand.primary"
          m={16}
          p={16}
          hAlignContent="center"
          vAlignContent="center"
          as={Link}
          to="/testimonials"
        >
          <Text textTransform="uppercase" fontWeight="bold" fontSize="lg">
            Customer Reviews
          </Text>
        </Flex>
      </BackgroundImage>
    </Flex>
  </>
)

export default AboutPageTemplate
