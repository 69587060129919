import React from 'react'
import { graphql } from 'gatsby'
import { AboutPageTemplate } from 'precision/pages/about'

const AboutPage = ({ data }) => {
  console.log(data)
  const {
    aboutJson,
    file: { childImageSharp: image },
  } = data
  return <AboutPageTemplate {...aboutJson} image={image} />
}
export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage {
    aboutJson {
      paragraphs
    }
    file(relativePath: { eq: "img/precision_trucks.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
